<template>
  <main>
    <div class="main-background bg-white" />
    <div class="title-date container">
      <!-- <div class="title">
        <span class="title__text text-white">
          Tarifs de l'Abbaye Tarifs de l'Abbaye Tarifs de l'Abbaye Tarifs de
          l'Abbaye Tarifs de l'Abbaye
        </span>
        <div class="title__background bg-red" />
      </div> -->
    </div>
    <div class="grille container">
      <div class="grille container grille__photo" v-if="results" :style="{ backgroundImage: `url(${BASEURL}/assets/${results})` }"  />
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";

export default defineComponent({
  name: "other",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const results = ref(null);
    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=other_image&filter={"id": { "_eq": "${props.slide}" },"type":{"_eq":"other"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);
      
        if(req.status == 200) {
          results.value = req.data.data.first().other_image
        }
    });

    return {
      results,
      BASEURL,
    };
  },
});
</script>
