<template>
  <footer class="footer text-white bg-purple">
    <p class="footer__text">
      {{ baseline }}
    </p>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "inc-footer",
  props: {
    baseline: {
      type: String,
      default: ''
    }
  },
  setup() {},
});
</script>
