<style>
  .main-background {
    background-color: black;
  }
</style>

<template>
  <main>
    <div :class="{ 'main-background main-background--dark' : isBlur  }" class="main-background--photo ">
      <img v-if="bgImage && isBlur" style="opacity: 0.8;" :src='`${BASEURL}/assets/${bgImage}?transforms=[["blur", 10]]`' alt="" />
      <img v-if="bgImage && !isBlur" style="opacity: 1;" :src='`${BASEURL}/assets/${bgImage}`' alt="" />
    </div>

    <div v-if="isBlur" class="container message">
      <div class="point-info">
        <svg
          class="point-info__icon fill-white"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 60.063 60.063"
        >
          <path
            d="M0-53.281A30.036,30.036,0,0,0-30.031-23.25,30.03,30.03,0,0,0,0,6.781,30.03,30.03,0,0,0,30.031-23.25,30.036,30.036,0,0,0,0-53.281Zm0,13.32a5.086,5.086,0,0,1,5.086,5.086A5.086,5.086,0,0,1,0-29.789a5.086,5.086,0,0,1-5.086-5.086A5.086,5.086,0,0,1,0-39.961ZM6.781-9.2A1.453,1.453,0,0,1,5.328-7.75H-5.328A1.453,1.453,0,0,1-6.781-9.2v-2.906a1.453,1.453,0,0,1,1.453-1.453h1.453v-7.75H-5.328a1.453,1.453,0,0,1-1.453-1.453v-2.906a1.453,1.453,0,0,1,1.453-1.453h7.75a1.453,1.453,0,0,1,1.453,1.453v12.109H5.328a1.453,1.453,0,0,1,1.453,1.453Z"
            transform="translate(30.031 53.281)"
          />
        </svg>

        <h1 class="point-info__title text-white">Point <span> Info </span></h1>
      </div>

      <h2 class="message__title text-red"> {{title}} </h2>

      <div v-html="content" class="message__content text-white"></div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";

export default defineComponent({
  name: "information-image",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const results = ref({})
    const content = ref(null);
    const bgImage = ref(null);
    const title = ref(null);
    const isBlur = ref(false)
    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=information_text,blur_background,information_backgroung_image,information_title,name&filter={"id": { "_eq": "${props.slide}" }, "type":{"_eq": "info"},"information_color":{"_eq": "image_background"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

        if(req.status == 200) {
          console.log(req.data.data)
          content.value = req.data.data.first().information_text;
          bgImage.value = req.data.data.first().information_backgroung_image;
          title.value = req.data.data.first().information_title;
          isBlur.value = req.data.data.first().blur_background;
        }
    });

    return {
      content,
      bgImage,
      title,
      BASEURL,
      results,
      isBlur
    };
  },
});
</script>
