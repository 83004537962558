<template>
  <div class="loader" :class="{ loaded: !isLoading }">
    <img :src="logoAbbaye" />
	</div>
</template>

<script>
import logoAbbaye from '@/assets/svg/logo-abbaye.svg'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  setup() {

    return {
      logoAbbaye
    }
  },
}
</script>

<style scoped>
	.loader {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: black;
		top:0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 999;
		opacity: 1;
		transition: ease all .6s;
		font-family: 'Raleway';
		font-size: .8vw;
		line-height: 1.2em;
		letter-spacing: 0.099vw;
		color: #FFF;
		pointer-events: none;
	}
	.loader.loaded {
		opacity: 0;
	}
</style>