<template>
  <main>
    <div class="main-background" :class="`bg-${bgColor}`"></div>

    <div class="container message">
      <div class="point-info point-info--big">
        <svg
          class="point-info__icon fill-white"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 60.063 60.063"
        >
          <path
            d="M0-53.281A30.036,30.036,0,0,0-30.031-23.25,30.03,30.03,0,0,0,0,6.781,30.03,30.03,0,0,0,30.031-23.25,30.036,30.036,0,0,0,0-53.281Zm0,13.32a5.086,5.086,0,0,1,5.086,5.086A5.086,5.086,0,0,1,0-29.789a5.086,5.086,0,0,1-5.086-5.086A5.086,5.086,0,0,1,0-39.961ZM6.781-9.2A1.453,1.453,0,0,1,5.328-7.75H-5.328A1.453,1.453,0,0,1-6.781-9.2v-2.906a1.453,1.453,0,0,1,1.453-1.453h1.453v-7.75H-5.328a1.453,1.453,0,0,1-1.453-1.453v-2.906a1.453,1.453,0,0,1,1.453-1.453h7.75a1.453,1.453,0,0,1,1.453,1.453v12.109H5.328a1.453,1.453,0,0,1,1.453,1.453Z"
            transform="translate(30.031 53.281)"
          />
        </svg>

        <h1 class="point-info__title text-white">Point <span>Info</span></h1>

        <svg
          class="point-info__eclats"
          xmlns="http://www.w3.org/2000/svg"
          width="931.153"
          height="395.628"
          viewBox="0 0 931.153 395.628"
        >
          <g transform="translate(0 0)">
            <g transform="translate(75.168 -906.608)">
              <path
                d="M205.794,24.645,220.005,265.4h20.436l16.39-240.751Z"
                transform="translate(163.509 881.963)"
              />
              <path
                d="M327.86,241.424l-13.97,13.158L190.792,63.987l51.4-35.875Z"
                transform="translate(-80.867 938.429)"
              />
              <path
                d="M383.423,184.456l-5.55,18.223L180.086,90.11l30.533-52.455Z"
                transform="translate(-255.254 1093.876)"
              />
              <path
                d="M227.269,207.259l-5.031-19.381,181.4-150.156,20.989,53.734Z"
                transform="translate(431.361 1094.978)"
              />
              <path
                d="M231.718,259.129,215.466,244,311.4,28.112l39.3,37.12Z"
                transform="translate(321.059 938.431)"
              />
            </g>
          </g>
        </svg>
      </div>
      

      <div class="message__container bg-white">
        <h2 class="message__title text-red"> {{title}} </h2>

        <div v-html="content" class="message__content text-black"></div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";

import AbbayeBackground from '@/assets/images/abbaye-background.png'

export default defineComponent({
  name: "information-color",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const content = ref(null);
    const bgColor = ref(null);
    const title = ref(null);
    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=id,information_text,information_background_color,information_title&filter={"id": { "_eq": "${props.slide}" },"type":{"_eq": "info"},"information_color":{"_eq": "colored_background"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);
        if(req.status == 200) {
          console.log(req.data.data)
          content.value = req.data.data.first().information_text;
          bgColor.value = req.data.data.first().information_background_color;
          title.value = req.data.data.first().information_title;
        }
    });
    return {
      title,
      content,
      bgColor,
      AbbayeBackground
    };
  },
});
</script>
