<template>
  <Loader :isLoading="loading" />
  <inc-header />
  <prices :slide="currentPage.id" v-if="currentPage?.type == 'prices'" />
  <other :slide="currentPage.id" v-if="currentPage?.type == 'other'" />
  <single-product :slide="currentPage.id" v-if="currentPage?.type == 'single_product'" />
  <one-exposition :slide="currentPage.id" v-if="currentPage?.type == 'one_exposition'" />
  <multiple-exposition :slide="currentPage.id" v-if="currentPage?.type == 'multiple_exposition'" />
  <information-image
    :slide="currentPage.id"
    v-if="
      currentPage?.type == 'info' &&
      currentPage?.information_color == 'image_background'
    "
  />
  <information-color
    :slide="currentPage.id"
    v-if="
      currentPage?.type == 'info' &&
      currentPage?.information_color == 'colored_background'
    "
  />
  <goodies v-if="currentPage?.type == 'goodies'" />
  <h2 v-if="pageExist == false"> Cette diapositive n'existe pas</h2>
  <inc-footer :baseline="baseline" />
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";

import Loader from '@/components/Loader.vue'

import IncHeader from "@/components/include/IncHeader.vue";
import IncFooter from "@/components/include/IncFooter.vue";

import Prices from "@/components/pages/Prices.vue";
import Other from "@/components/pages/Other.vue";
import SingleProduct from "@/components/pages/SingleProduct.vue";
import OneExposition from "@/components/pages/OneExposition.vue";
import MultipleExposition from "@/components/pages/MultipleExposition.vue";
import InformationImage from "@/components/pages/InformationImage.vue";
import InformationColor from "@/components/pages/InformationColor.vue";
import Goodies from "@/components/pages/Goodies.vue";

export default defineComponent({
  name: "app",
  components: {
    IncHeader,
    IncFooter,
    Prices,
    SingleProduct,
    OneExposition,
    MultipleExposition,
    InformationImage,
    InformationColor,
    Goodies,
    Other,
    Loader
  },
  setup() {
    Array.prototype.first = function () {
      return this[0];
    };

    const loading = ref(true)
    const currentPage = ref({});
    const results = ref({});
    const pageNumber = ref({});
    const pageDuration = ref({});
    const baseline = ref('');

    let nextPage;
    const pageExist = ref(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let selectedPage = urlParams.get("select-page");

    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT = "/items/application_totem?fields=id,type,information_color,page_duration,baseline";

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

        console.log(req)
  
      if (req.status == 200) {
        results.value = req.data.data;
        pageNumber.value = results.value.length - 1;
      }
      
      //If user want to select a specific page with url param
      if (selectedPage != null) {

        currentPage.value = results.value.find(
          (pageName) => pageName.id === parseInt(selectedPage)
        );

        // If the selected page doesn't exist, show div to user
        if (typeof currentPage.value === 'undefined') {
          pageExist.value = false;
        }

        pageDuration.value = 20000;

      } else if (localStorage.getItem("currentPage") === null || parseInt(localStorage.getItem("currentPage")) == pageNumber.value) {

        currentPage.value = results.value[0]
        localStorage.setItem("currentPage", 0);
        pageDuration.value = currentPage.value.page_duration;

      } else {
        nextPage = parseInt(localStorage.getItem("currentPage")) + 1;

        localStorage.setItem("currentPage", nextPage);

        currentPage.value = results.value[nextPage]
        pageDuration.value = currentPage.value.page_duration;
      }

        baseline.value = currentPage.value.baseline;

      const reloadTimer = async () => {
        
        console.log("timeout start");
        axios
          .get("https://api.abbaye-st-jacut.com/server/ping")
          .then((response) => {
            if (response.status == 200) {
              loading.value = true
              document.location.reload();
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log("Response error");
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log("Request error");
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
            setTimeout(reloadTimer, pageDuration.value * 60000);
          });
      };
      
      setTimeout(reloadTimer, pageDuration.value * 60000);

      setTimeout(() => {
        loading.value = false
      }, 400)

    });
  
    return {
      currentPage,
      pageExist,
      loading,
      baseline
    };
  },
});
</script>

<style lang="scss">
  @import "@/assets/scss/styles.scss";
</style>