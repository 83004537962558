<script>
import moment from 'moment'

function formatHours(startDate, endDate) {
    let result = ''
    if (startDate.format('Hmm') != '000') {
        if (startDate.format('Hmm') == endDate.format('Hmm')) {
            result += `${startDate.format(' [à partir de] H[h]mm')}`
        } else {
            result += `${startDate.format(' [de] H[h]mm')}  ${endDate.format('[à] H[h]mm')}`
        }
    }
    return result
}


const sentence = ({ start_date, end_date, showHours = true }) => {
            
            moment.locale('fr')
            let dateSentence
            let startDate = moment(start_date)
            let endDate = moment(end_date)
            if (startDate.format('DDMMYYYY') == endDate.format('DDMMYYYY')) { // Meme jour
                dateSentence = `Le ${startDate.format('D MMMM YYYY')}`
            } else {
                if (startDate.format('MMYYYY') == endDate.format('MMYYYY')) { // Meme mois
                    dateSentence = `Du ${startDate.format('D')}  ${endDate.format('[au] D MMMM YYYY')}`
                } else {
                    if (startDate.format('YYYY') == endDate.format('YYYY')) { // Meme année
                        dateSentence = `Du ${startDate.format('D MMMM')}  ${endDate.format('[au] D MMMM YYYY')} `
                    } else {
                        dateSentence = `Du ${startDate.format('D MMMM YYYY')}  ${endDate.format('[au] D MMMM YYYY')}`
                    }
                 }
            }
            if (showHours) {
                dateSentence += formatHours(startDate, endDate, dateSentence)
            }
            return dateSentence
        }

export default () => {

    return {
        sentence
    }
}
</script>