<template>
  <main>
    <div class="main-background bg-blue" />

    <div class="title-date container">
      <div class="title">
        <span class="title__text text-white"> {{title}} </span>

        <div class="title__background bg-green"></div>
      </div>

      <div class="date text-black bg-white">
        <span class="date__long"> {{subTitle}} </span>
      </div>
    </div>

    <div class="container swiper">
      <swiper
        :slides-per-view="'auto'"     
        :space-between="40"
        :direction="'vertical'"
        :autoplay="{ delay: 5000 }"
        :speed="1000"
      >
        <swiper-slide
          v-for="result in results"
          :key="result"
          class="card card--horizontal swiper-slide"
        >
          <figure class="card__photo">
            <img
              v-if="result?.application_totem_exposition_id.product.image"
              :src="`${BASEURL}/assets/${result.application_totem_exposition_id.product.image}`"
              alt=""
            />

            <div class="card__effect bg-white"></div>
          </figure>

          <div class="card__container bg-white">
            <div class="card__infos border-gray-dark">
              <span class="card__date text-blue">

                {{FormatDate(
                  result.application_totem_exposition_id.product.product_schedule[0].start_date, 
                  result.application_totem_exposition_id.product.product_schedule[0].end_date)
                  }}

              </span>

              <h2 class="card__title text-black">
                {{ result.application_totem_exposition_id.product.title }}
              </h2>

              <span class="card__organisation">
                {{ result.application_totem_exposition_id.product.speaker }}
              </span>
            </div>

            <div class="direction direction--vertical">
              <span
                class="direction__icon border-black-light"
                :class="`rotate-${result.application_totem_exposition_id.conference_room.direction_totem}`" 
              >
                <svg
                  class="fill-black-light"
                  xmlns="http://www.w3.org/2000/svg"
                  width="94.752"
                  height="94.752"
                  viewBox="0 0 94.752 94.752"
                >
                  <path
                    class="st1"
                    d="M15.066,43.632h0a.4.4,0,0,1-.371-.248.408.408,0,0,1,.091-.446L30.553,27.373a.4.4,0,0,0-.281-.695H.4a.412.412,0,0,1-.4-.416V16.706A.4.4,0,0,1,.136,16.4.4.4,0,0,1,.4,16.3a.408.408,0,0,1,.052,0c.07.005.141.008.211.008s.142,0,.211-.008H30.2a.406.406,0,0,0,.279-.7L16.2.872a.407.407,0,0,1-.082-.434.4.4,0,0,1,.361-.251L29.943,0h.012a.369.369,0,0,1,.277.125L51.5,21.6a.415.415,0,0,1,0,.591L30.139,43.1a.489.489,0,0,1-.279.124l-14.786.4Z"
                    transform="translate(72.471 68.267) rotate(180)"
                  />
                </svg>
              </span>

              <h2 class="direction__title text-black-light">
                {{
                  result.application_totem_exposition_id.conference_room.name
                }}
              </h2>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import useDateFormat from '@/components/utils/UseDateFormat.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Autoplay } from 'swiper'

SwiperCore.use([Pagination, Autoplay])

import axios from "axios";

import AbbayeBackground from "@/assets/images/abbaye-background.png";

export default defineComponent({
  name: "multiple-exposition",
  components: {
        Swiper,
        SwiperSlide,
  },
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const results = ref({});
    const title = ref({});
    const subTitle = ref({});
    const { sentence: FormatDate } = useDateFormat();

    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=exposition_title,exposition_sub_title,exposition.application_totem_exposition_id.product.title,exposition.application_totem_exposition_id.product.image,exposition.application_totem_exposition_id.product.speaker,exposition.application_totem_exposition_id.product.product_schedule.start_date,exposition.application_totem_exposition_id.product.product_schedule.end_date,exposition.application_totem_exposition_id.conference_room.*&filter={"id": { "_eq": "${props.slide}" },"type":{"_eq":"multiple_exposition"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

      if (req.status == 200) {
        title.value = req.data.data.first().exposition_title
        subTitle.value = req.data.data.first().exposition_sub_title
        results.value = req.data.data.first().exposition;
      }
    });

    return {
      title,
      subTitle,
      results,
      AbbayeBackground,
      BASEURL,
      FormatDate
    };
  },
});
</script>