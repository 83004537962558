<template>
  <main>
    <div class="main-background bg-white" />
    <div class="title-date container">
      <div class="title">
        <span class="title__text text-white">
          {{ title }}
        </span>
        <div class="title__background bg-red" />
      </div>
    </div>
    <div class="grille container" :style="{ backgroundImage: `url(${BASEURL}/assets/${results})` }" />
      <!--<img v-if="results" :src="`${BASEURL}/assets/${results}`" alt="" class="grille__photo" />-->
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";

export default defineComponent({
  name: "tarif",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const results = ref(null);
    const title = ref('')
    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=price_table,name&filter={"id": { "_eq": "${props.slide}" }, "type":{"_eq":"prices"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

        if(req.status == 200) {
          results.value = req.data.data.first().price_table
          title.value = req.data.data.first().name
        }
    });

    return {
      results,
      BASEURL,
      title
    };
  },
});
</script>
