<template>
  <header class="header">
    <div class="container header__container">
      <span class="header__icon">
        <inline-svg
          :src="require('@/assets/svg/logo-abbaye.svg')"
          width="150" 
          height="150"
          fill="black"
        />
      </span>
    </div>
  </header>
</template>

<script>
import { defineComponent } from "vue";
import InlineSvg from 'vue-inline-svg';

// import AbbayeLogo from '@/assets/svg/logo-abbaye.svg?inline'

export default defineComponent({
  name: "inc-header",
  components: {
    InlineSvg
  },
  setup() {
    return {}
  },
});
</script>
