<template>
  <main>
    <div class="main-background bg-gray-light" />

    <div class="title-date container">
      <div class="title">
        <span class="title__text text-white">Les produits de l'abbaye </span>

        <div class="title__background bg-red" />
      </div>
    </div>

    <div class="produit container">
      <div class="produit__display">
        <div v-for="product in results" :key="product" class="produit__card">
          <figure class="produit__photo bg-gray-dark">
            <img
              :src="`${BASEURL}/assets/${product.application_totem_goodies_id.image}`"
              alt=""
              class=""
            />
          </figure>

          <div class="produit__infos bg-white">
            <h2 class="produit__title text-black">
              {{ product.application_totem_goodies_id.title }}
            </h2>

            <span class="produit__price text-red">
              {{ product.application_totem_goodies_id.price }} €
              </span>
          </div>
        </div>
      </div>

      <p class="information text-black">
        Disponible à <span> la boutique de l'Abbaye </span> <br />
        ou en ligne <span> sur notre site : </span> <br />
        <a href="" class="text-red"> www.abbaye-st-jacut.com </a>
      </p>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref } from "vue";
import axios from "axios";

export default defineComponent({
  name: "produit",
  setup() {
    Array.prototype.first = function () {
      return this[0];
    };
    const results = ref(null);
    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      '/items/application_totem?fields=goodies.application_totem_goodies_id.*&filter={"type":{"_eq":"goodies"}}';

    axios
      .create({
        baseURL: BASEURL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .get(ENDPOINT)
      .then((data) => (results.value = data.data.data.first().goodies));

    return {
      results,
      BASEURL,
    };
  },
});
</script>