<template>
  <main>
    <div class="main-background bg-blue" />
    <div class="title-date container">
      <div class="title">
        <span class="title__text text-white">
          {{title}} 
        </span>
        <div class="title__background bg-green" />
      </div>
      <div class="date text-black bg-white">
        <span class="date__long">
          {{subTitle}}
        </span>
      </div>
    </div>

    <div class="main-container container">
      <div class="card">
        <figure class="card__photo bg-gray-dark">
          <img
            v-if="product?.image"
            :src="`${BASEURL}/assets/${product.image}`"
            alt=""
          />

          <div class="card__effect bg-white"></div>
        </figure>

        <div class="card__container bg-white">
          <div class="card__infos border-gray-dark">
            <span class="card__date text-blue">
              {{date_formated}}
            </span>

            <h2 class="card__title text-black line-effect">
              {{product.title}}
            </h2>

            <h3 class="card__subtitle text-blue">
              {{product.baseline}}
            </h3>

            <span class="card__organisation">
              {{product.speaker}}
            </span>
          </div>

          <div class="direction">
            <span class="direction__icon border-black-light"
            :class="`rotate-${room.direction_totem}`" >
              <svg class="fill-black-light" xmlns="http://www.w3.org/2000/svg" width="94.752" height="94.752"
                viewBox="0 0 94.752 94.752">
                <path class="st1"
                  d="M15.066,43.632h0a.4.4,0,0,1-.371-.248.408.408,0,0,1,.091-.446L30.553,27.373a.4.4,0,0,0-.281-.695H.4a.412.412,0,0,1-.4-.416V16.706A.4.4,0,0,1,.136,16.4.4.4,0,0,1,.4,16.3a.408.408,0,0,1,.052,0c.07.005.141.008.211.008s.142,0,.211-.008H30.2a.406.406,0,0,0,.279-.7L16.2.872a.407.407,0,0,1-.082-.434.4.4,0,0,1,.361-.251L29.943,0h.012a.369.369,0,0,1,.277.125L51.5,21.6a.415.415,0,0,1,0,.591L30.139,43.1a.489.489,0,0,1-.279.124l-14.786.4Z"
                  transform="translate(72.471 68.267) rotate(180)" />
              </svg>
            </span>

            <div class="direction__container">
              <h2 class="direction__title text-black-light">
                {{room.name}}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import useDateFormat from '@/components/utils/UseDateFormat.vue';
import axios from "axios";

import AbbayeBackground from '@/assets/images/abbaye-background.png'

export default defineComponent({
  name: "one-exposition",
  props: {
    slide: {
      type: Number,
      default: 21
    }
  },
  setup(props) {
    Array.prototype.first = function () {
      return this[0];
    };
    const { sentence } = useDateFormat();
    const results = ref({});
    const title = ref({});
    const subTitle = ref({});
    const product = ref({});
    const start_date = ref({});
    const end_date = ref({});
    const date_formated = ref({});
    const room = ref({});

    const TOKEN = "i8jd2qf";
    const BASEURL = "https://api.abbaye-st-jacut.com";
    const ENDPOINT =
      `/items/application_totem?fields=exposition_title,exposition_sub_title,exposition.application_totem_exposition_id.conference_room.*,exposition.application_totem_exposition_id.product.title,exposition.application_totem_exposition_id.product.baseline,exposition.application_totem_exposition_id.product.image,exposition.application_totem_exposition_id.product.speaker,exposition.application_totem_exposition_id.product.product_schedule.start_date,exposition.application_totem_exposition_id.product.product_schedule.end_date&filter={"id": { "_eq": "${props.slide}" },"type":{"_eq":"one_exposition"}}`;

    onMounted(async () => {
      const req = await axios
        .create({
          baseURL: BASEURL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .get(ENDPOINT);

      if (req.status == 200) {
        title.value = req.data.data.first().exposition_title
        subTitle.value = req.data.data.first().exposition_sub_title
        results.value = req.data.data.first().exposition.first().application_totem_exposition_id
        product.value = results.value.product,
        start_date.value = results.value.product.product_schedule.first().start_date,
        end_date.value = results.value.product.product_schedule.first().end_date,
        room.value = results.value.conference_room
        date_formated.value = sentence(start_date.value, end_date.value)
      }

    });

    return {
      title,
      subTitle,
      product,
      date_formated,
      room,
      AbbayeBackground,
      BASEURL,
    };
  },
});
</script>